import axios from "./axios-auth";

const state = {
  doctors: [],
  patients: [],
  patients_list: [],
  notifications: [],
};

const getters = {
  doctors(state) {
    return state.doctors;
  },
  patients(state) {
    return state.patients;
  },
  patients_list(state) {
    return state.patients_list;
  },
  notifications(state) {
    return state.notifications;
  },
};

const actions = {
  fetchDoctors({ rootGetters, commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootGetters.API}/doctors`)
        .then((response) => {
          if (response.data.code == 200) {
            commit("SET_DOCTORS", response.data.results);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  editDoctor({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${rootGetters.API}/doctors/${payload.user_id}`, payload)
        .then((response) => {
          if (response.data.code == 200) {
            dispatch("fetchDoctors");
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateProfilePhoto({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${rootGetters.API}/users/upload-profile-image/${payload.user_id}`,
          payload.data
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removeDoctors({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${rootGetters.API}/doctors/remove`, {
          data: { doctors: payload },
        })
        .then((response) => {
          if (response.data.code == 200) {
            dispatch("fetchDoctors");
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchPatients({ rootGetters, commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootGetters.API}/patients`)
        .then((response) => {
          if (response.data.code == 200) {
            commit("SET_PATIENTS", response.data.results);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchPatientsList({ rootGetters, commit }, payload) {
    return new Promise((resolve, reject) => {
      let url = `${rootGetters.API}/patients/list`;

      if (payload) {
        url += `?${payload}`;
      }

      axios
        .get(url)
        .then((response) => {
          if (response.data.code == 200) {
            commit("SET_PATIENTS_LIST", response.data.results);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createPatient({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${rootGetters.API}/patients`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  editPatient({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${rootGetters.API}/patients/${payload.user_id}`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchNotifications({ rootGetters, commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootGetters.API}/users/notifications`)
        .then((response) => {
          if (response.data.code == 200) {
            commit("SET_NOTIFICATIONS", response.data.results);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  markAsRead({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${rootGetters.API}/users/notifications/${payload}`)
        .then((response) => {
          if (response.data.code == 200) {
            dispatch("fetchNotifications");
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_DOCTORS: (state, payload) => {
    state.doctors = payload;
  },
  SET_PATIENTS: (state, payload) => {
    state.patients = payload;
  },
  SET_PATIENTS_LIST: (state, payload) => {
    state.patients_list = payload;
  },
  SET_NOTIFICATIONS: (state, payload) => {
    state.notifications = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
