import axios from "./axios-auth";

const state = {
  branches: [],
  zones: [],
  governates: [],
};

const getters = {
  branches(state) {
    return state.branches;
  },
  zones(state) {
    return state.zones;
  },
  governates(state) {
    return state.governates;
  },
};

const actions = {
  fetchGovernates({ rootGetters, commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootGetters.API}/governates`)
        .then((response) => {
          if (response.data.code == 200) {
            commit("SET_GOVERNATES", response.data.results);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchBranches({ rootGetters, commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootGetters.API}/branches`)
        .then((response) => {
          if (response.data.code == 200) {
            commit("SET_BRANCHES", response.data.results);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createBranch({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${rootGetters.API}/branches`, payload)
        .then((response) => {
          if (response.data.code == 200) {
            dispatch("fetchBranches");
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateBranch({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${rootGetters.API}/branches/${payload.id}`, payload)
        .then((response) => {
          if (response.data.code == 200) {
            dispatch("fetchBranches");
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removeBranch({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${rootGetters.API}/branches`, {
          data: { payload },
        })
        .then((response) => {
          if (response.data.code == 200) {
            dispatch("fetchBranches");
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchZones({ rootGetters, commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootGetters.API}/zones`)
        .then((response) => {
          if (response.data.code == 200) {
            commit("SET_ZONES", response.data.results);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createZone({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${rootGetters.API}/zones`, payload)
        .then((response) => {
          if (response.data.code == 200) {
            dispatch("fetchZones");
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateZone({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${rootGetters.API}/zones/${payload.id}`, payload)
        .then((response) => {
          if (response.data.code == 200) {
            dispatch("fetchZones");
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removeZone({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${rootGetters.API}/zones`, {
          data: { payload },
        })
        .then((response) => {
          if (response.data.code == 200) {
            dispatch("fetchZones");
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  attachZone({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${rootGetters.API}/branch/${payload.id}/zones`, payload.zones)
        .then((response) => {
          if (response.data.code == 200) {
            dispatch("fetchBranches");
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_BRANCHES: (state, paylaod) => {
    state.branches = paylaod;
  },
  SET_ZONES: (state, paylaod) => {
    state.zones = paylaod;
  },
  SET_GOVERNATES: (state, paylaod) => {
    state.governates = paylaod;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
