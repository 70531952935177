// socket.io
import { io } from "socket.io-client";

const config = require("../config.json");

const socket = io(config["websockets"]["test"], {
  transports: ["websocket"],
  autoConnect: false,
  query: {
    token: localStorage.getItem("token"),
  },
});

socket.on("connect", () => {
  console.log("connected to socket!!!");
});

export default socket;
