import axios from "./axios-auth";

const state = {
  teeth: [],
  tooth: null,
};

const getters = {
  all(state) {
    return state.teeth;
  },
  tooth(state) {
    return state.tooth;
  },
};

const actions = {
  createTeeth({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${rootGetters.API}/teeth/bulk`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchTeeth({ rootGetters, commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootGetters.API}/teeth`)
        .then((response) => {
          if (response.data.code == 200) {
            commit("SET_TEETH", response.data.results);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateImage({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${rootGetters.API}/teeth/image`, payload)
        .then((response) => {
          if (response.data.code == 200) {
            dispatch("fetchTeeth");
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateTooth({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${rootGetters.API}/teeth/details`, payload)
        .then((response) => {
          if (response.data.code == 200) {
            dispatch("fetchTeeth");
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removeTeeth({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${rootGetters.API}/teeth/remove`, payload)
        .then((response) => {
          if (response.data.code == 200) {
            dispatch("fetchTeeth");
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async setTooth({ commit }, payload) {
    commit("SET_TOOTH", payload);
  },
};

const mutations = {
  SET_TEETH: (state, payload) => {
    state.teeth = payload;
  },
  SET_TOOTH: (state, payload) => {
    state.tooth = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
