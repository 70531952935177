import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import "./assets/tailwind.css";

// Websockets
import socket from "./socket";
Vue.prototype.$socket = socket;

import VueSweetalert2 from "vue-sweetalert2";

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";

const options = {
  confirmButtonColor: "#059669",
  cancelButtonColor: "#ef4444",
};

Vue.use(VueSweetalert2, options);

// Vue Select
import vSelect from "vue-select";

Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";

Vue.use(PerfectScrollbar);

// Vue Moment
const moment = require("moment-timezone");

moment.tz.setDefault("Asia/Riyadh");

Vue.use(require("vue-moment"), {
  moment,
});

// Native Desktop Notifications
import VueNativeNotification from "vue-native-notification";

Vue.use(VueNativeNotification, {
  // Automatic permission request before
  // showing notification (default: true)
  requestOnNotify: true,
});

// ICONS
import BackIcon from "vue-material-design-icons/ArrowLeft.vue";
import BellIcon from "vue-material-design-icons/Bell.vue";
import PlusThinIcon from "vue-material-design-icons/Plus.vue";
import MenuIcon from "vue-material-design-icons/Menu.vue";
import CircleIcon from "vue-material-design-icons/Circle.vue";
import UserIcon from "vue-material-design-icons/Account.vue";
import EditIcon from "vue-material-design-icons/LeadPencil.vue";
import RemoveIcon from "vue-material-design-icons/TrashCanOutline.vue";
import CloseIcon from "vue-material-design-icons/Close.vue";
import PlusIcon from "vue-material-design-icons/PlusThick.vue";
import MinusIcon from "vue-material-design-icons/Minus.vue";
import ListIcon from "vue-material-design-icons/ClipboardText.vue";
import EyeIcon from "vue-material-design-icons/Eye.vue";
import FileIcon from "vue-material-design-icons/File.vue";
import CheckIcon from "vue-material-design-icons/CheckCircle.vue";
import SelectIcon from "vue-material-design-icons/CheckBold.vue";
import UploadIcon from "vue-material-design-icons/Upload.vue";
import CollapseIcon from "vue-material-design-icons/ChevronRight.vue";

import SendIcon from "vue-material-design-icons/Send.vue";
import ChatIcon from "vue-material-design-icons/Chat.vue";
import AttachmentIcon from "vue-material-design-icons/Attachment.vue";

import PDFIcon from "vue-material-design-icons/FilePdfBox.vue";
import WordIcon from "vue-material-design-icons/FileWordBox.vue";
import URLIcon from "vue-material-design-icons/LinkBoxVariant.vue";
import ImageIcon from "vue-material-design-icons/Image.vue";

import DiscountIcon from "vue-material-design-icons/PercentCircle.vue";
import CancelIcon from "vue-material-design-icons/CloseCircle.vue";

import ReplyIcon from "vue-material-design-icons/Reply.vue";

Vue.component("reply-icon", ReplyIcon);
Vue.component("send-icon", SendIcon);
Vue.component("chat-icon", ChatIcon);
Vue.component("attachment-icon", AttachmentIcon);
Vue.component("image-icon", ImageIcon);
Vue.component("file-icon", FileIcon);
Vue.component("cancel-icon", CancelIcon);

Vue.component("discount-icon", DiscountIcon);
Vue.component("back-icon", BackIcon);
Vue.component("bell-icon", BellIcon);
Vue.component("plus-outline-icon", PlusThinIcon);
Vue.component("menu-icon", MenuIcon);
Vue.component("circle-icon", CircleIcon);
Vue.component("user-icon", UserIcon);
Vue.component("edit-icon", EditIcon);
Vue.component("remove-icon", RemoveIcon);
Vue.component("close-icon", CloseIcon);
Vue.component("plus-icon", PlusIcon);
Vue.component("minus-icon", MinusIcon);
Vue.component("list-icon", ListIcon);
Vue.component("eye-icon", EyeIcon);
Vue.component("file-icon", FileIcon);
Vue.component("check-icon", CheckIcon);
Vue.component("select-icon", SelectIcon);
Vue.component("upload-icon", UploadIcon);
Vue.component("collapse-icon", CollapseIcon);

Vue.component("pdf-icon", PDFIcon);
Vue.component("word-icon", WordIcon);
Vue.component("link-icon", URLIcon);

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
