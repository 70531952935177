import axios from "axios";
import store from "../store";

const instance = axios.create();

instance.defaults.headers.common[
  "Authorization"
] = `Bearer ${localStorage.getItem("token")}`;

instance.interceptors.request.use((config) => {
  // Do something before request is sent
  return config;
});

instance.interceptors.response.use(
  (res) => {
    // console.log(res);

    return res;
  },
  (err) => {
    // console.log("from interceptor", err.response.data);
  }
);

export default instance;
