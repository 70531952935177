import axios from "./axios-auth";

const state = {
  contacts: [],
  conversations: [],
};

const getters = {
  contacts(state) {
    return state.contacts;
  },
  conversations(state) {
    return state.conversations;
  },
};

const actions = {
  fetchContactList({ rootGetters, commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${rootGetters["API"]}/chat/list-contacts`).then((response) => {
        if (response.data.code == 200) {
          commit("SET_CONTACT_LIST", response.data.results);
        }

        resolve(response);
      });
    });
  },
  fetchConversationList({ rootGetters, commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootGetters["API"]}/chat/list-conversations`)
        .then((response) => {
          if (response.data.code == 200) {
            commit("SET_CONVERSATION_LIST", response.data.results);
          }

          resolve(response);
        });
    });
  },
  createConversation({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${rootGetters["API"]}/chat/create-conversation`, payload)
        .then((response) => {
          if (response.data.code == 200) {
            dispatch("fetchConversationList");
          }

          resolve(response);
        });
    });
  },
  sendMessage({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${rootGetters["API"]}/chat/send-message`, payload)
        .then((response) => {
          resolve(response);
        });
    });
  },
};

const mutations = {
  SET_CONTACT_LIST: (state, payload) => {
    state.contacts = payload;
  },
  SET_CONVERSATION_LIST: (state, payload) => {
    state.conversations = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
