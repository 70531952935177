import axios from "axios";
import axiauth from "./axios-auth";

const state = {
  token: null,
  user: null,
};

const getters = {
  isAuth(state) {
    return state.token && state.user;
  },
  user(state) {
    return state.user;
  },
};

const actions = {
  register({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${rootGetters.API}/users/register`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createPateint({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      axiauth
        .post(`${rootGetters.API}/patients`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  login({ rootGetters, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${rootGetters.API}/users/login`, payload)
        .then((response) => {
          if (response.data.code == 200) {
            const { token, ...user } = response.data.results;

            commit("SET_TOKEN", token);
            commit("SET_USER", user);
            localStorage.setItem("token", token);
            localStorage.setItem("user", user);

            if ([2, 3, 4].includes(user.role.id)) {
              if (user.role.id == 2) {
                window.location.replace(`/requests/${user.user_id}`);
              } else {
                window.location.replace("/requests");
              }
            }

            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async logout({ commit }) {
    await commit("SET_TOKEN", null);
    await commit("SET_USER", null);

    localStorage.removeItem("token");
    localStorage.removeItem("user");

    window.location.replace("/");
  },
};

const mutations = {
  SET_TOKEN: (state, payload) => {
    state.token = payload;
  },
  SET_USER: (state, payload) => {
    state.user = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
