import axios from "./axios-auth";

const state = {
  types: [],
};

const getters = {
  types(state) {
    return state.types;
  },
};

const actions = {
  fetchTypes({ rootGetters, commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootGetters.API}/types`)
        .then((response) => {
          if (response.data.code == 200) {
            commit("SET_TYPES", response.data.results);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_TYPES: (state, payload) => {
    state.types = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
