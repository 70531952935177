import axios from "./axios-auth";

const state = {
  roles: [],
  pages: [],
  account_types: [],
};

const getters = {
  roles(state) {
    return state.roles;
  },
  pages(state) {
    return state.pages;
  },
  account_types(state) {
    return state.account_types;
  },
};

const actions = {
  fetchRoles({ rootGetters, commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootGetters.API}/permissions/roles`)
        .then((response) => {
          if (response.data.code == 200) {
            commit("SET_ROLES", response.data.results);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchPages({ rootGetters, commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootGetters.API}/permissions/pages`)
        .then((response) => {
          if (response.data.code == 200) {
            commit("SET_PAGES", response.data.results);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchAccountTypes({ rootGetters, commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootGetters.API}/permissions/account-types`)
        .then((response) => {
          if (response.data.code == 200) {
            commit("SET_ACCOUNT_TYPES", response.data.results);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createRole({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${rootGetters.API}/permissions/roles`, payload)
        .then((response) => {
          if (response.data.code == 200) {
            dispatch("fetchRoles");
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  editRole({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${rootGetters.API}/permissions/roles`, payload)
        .then((response) => {
          if (response.data.code == 200) {
            dispatch("fetchRoles");
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removeRole({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${rootGetters.API}/permissions/roles/${payload}`)
        .then((response) => {
          if (response.data.code == 200) {
            dispatch("fetchRoles");
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_ROLES: (state, payload) => {
    state.roles = payload;
  },
  SET_PAGES: (state, payload) => {
    state.pages = payload;
  },
  SET_ACCOUNT_TYPES: (state, payload) => {
    state.account_types = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
