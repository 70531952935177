import axios from "./axios-auth";

const state = {
  ranks: [],
  requests: [],
};

const getters = {
  ranks(state) {
    return state.ranks;
  },
  requests(state) {
    return state.requests;
  },
};

const actions = {
  fetchRedeemRequests({ rootGetters, commit }, query) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${rootGetters["API"]}/doctors/redeem-requests?${query.toString()}`
        )
        .then((response) => {
          if (response.data.code == 200) {
            commit("SET_DOCTOR_REDEEM_REQUESTS", response.data.results);
          }

          resolve(response);
        });
    });
  },
  approveRedeem({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const { id, query, ...rest } = payload;

      const URL = rootGetters["API"];

      axios
        .put(`${URL}/doctors/redeem/${id}/requests`, rest)
        .then((response) => {
          if (response.data.code == 200) {
            dispatch("fetchRedeemRequests", query);
          }

          resolve(response);
        });
    });
  },
  fetchAllRanks({ rootGetters, commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${rootGetters["API"]}/ranks`).then((response) => {
        if (response.data.statusCode == 200) {
          commit("SET_DOCTOR_RANKS", response.data.ranks);
        }

        resolve(response);
      });
    });
  },
  createRank({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${rootGetters["API"]}/ranks/one`, payload)
        .then((response) => {
          if (response.data.statusCode == 200) {
            dispatch("fetchAllRanks");
          }

          resolve(response);
        });
    });
  },
  editRank({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${rootGetters["API"]}/ranks/${payload.id}`, payload)
        .then((response) => {
          if (response.data.statusCode == 200) {
            dispatch("fetchAllRanks");
          }

          resolve(response);
        });
    });
  },
  removeRank({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${rootGetters["API"]}/ranks/remove/${payload}`)
        .then((response) => {
          if (response.data.statusCode == 200) {
            dispatch("fetchAllRanks");
          }

          resolve(response);
        });
    });
  },
};

const mutations = {
  SET_DOCTOR_RANKS: (state, payload) => {
    state.ranks = payload;
  },
  SET_DOCTOR_REDEEM_REQUESTS: (state, payload) => {
    state.requests = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
