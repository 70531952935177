import axios from "./axios-auth";

const state = {
  requests: [],
  doctor_requests: [],
  patient_requests: [],
  attachments: [],
  doctor: null,
  detail_id: null,
  filters: null,
};

const getters = {
  requests(state) {
    return state.requests;
  },
  doctor_requests(state) {
    return state.doctor_requests;
  },
  patient_requests(state) {
    return state.patient_requests;
  },
  attachments(state) {
    return state.attachments;
  },
  doctor(state) {
    return state.doctor;
  },
  details(state) {
    return state.detail_id;
  },
};

const actions = {
  createRequest({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${rootGetters.API}/requests`, payload.data)
        .then((response) => {
          if (response.data.code == 200) {
            // fetch requests
            dispatch("fetchRequests");
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  searchRequests({ rootGetters, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${rootGetters.API}/requests/search`, payload)
        .then((response) => {
          if (response.data.code == 200) {
            commit("SET_REQUESTS", response.data.results);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchRequests({ rootGetters, commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootGetters.API}/requests`)
        .then((response) => {
          if (response.data.code == 200) {
            commit("SET_REQUESTS", response.data.results);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchOneRequests({ rootGetters, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootGetters.API}/requests/${payload}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchDoctorRequests({ rootGetters, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootGetters.API}/requests/doctor/${payload}`)
        .then((response) => {
          if (response.data.code == 200) {
            commit("SET_DOCTOR_REQUESTS", response.data.results);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  uploadDetalAttachments({ rootGetters, dispatch, rootState }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${rootGetters.API}/attachments`, payload.data)
        .then((response) => {
          if (response.data.code == 200) {
            const doctor_id = rootState.auth.user.user_id;

            dispatch("fetchDoctorRequests", doctor_id);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  uploadAttachments({ rootGetters, dispatch, state }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${rootGetters.API}/attachments`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  uploadLinkAttachments({ rootGetters, dispatch, state }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${rootGetters.API}/attachments/links`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removeDoctorAttachment({ rootGetters, dispatch, rootState }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${rootGetters.API}/attachments`, {
          data: { files: payload.files },
        })
        .then((response) => {
          if (response.data.code == 200) {
            const doctor_id = rootState.auth.user.user_id;

            dispatch("fetchDoctorRequests", doctor_id);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removeAttachment({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${rootGetters.API}/attachments`, {
          data: { files: payload.files },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  changeBranch({ rootGetters, dispatch, rootState }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${rootGetters.API}/requests/${payload.request}/change-branch/${payload.branch}`
        )
        .then((response) => {
          if (response.data.code == 200) {
            const doctor_id = rootState.auth.user.user_id;

            dispatch("fetchDoctorRequests", doctor_id);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addDetails({ rootGetters, dispatch, rootState }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${rootGetters.API}/requests/${payload.request}/add-details`,
          payload.data
        )
        .then((response) => {
          if (response.data.code == 200) {
            const doctor_id = rootState.auth.user.user_id;

            dispatch("fetchDoctorRequests", doctor_id);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateDetail({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${rootGetters.API}/requests/${payload.request}/edit-details`,
          payload.data
        )
        .then((response) => {
          if (response.data.code == 200) {
            dispatch("fetchRequests");
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removeDetails({ rootGetters, dispatch, rootState }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${rootGetters.API}/requests/details`, {
          data: { details: payload.list },
        })
        .then((response) => {
          if (response.data.code == 200) {
            const doctor_id = rootState.auth.user.user_id;

            dispatch("fetchDoctorRequests", doctor_id);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateTeeth({ rootGetters, dispatch, rootState }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${rootGetters.API}/requests/${payload.request_id}/teeth`,
          payload.data
        )
        .then((response) => {
          if (response.data.code == 200) {
            const doctor_id = rootState.auth.user.user_id;

            dispatch("fetchDoctorRequests", doctor_id);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async setAttachments({ commit }, payload) {
    await commit("SET_ATTACHMENTS", payload);
  },
  async setDoctorId({ commit }, payload) {
    await commit("SET_DOCTOR", payload);
  },
  async setDetailId({ commit }, payload) {
    await commit("SET_DETAIL_ID", payload);
  },
  async setFilters({ commit }, payload) {
    await commit("SET_FILTERS", payload);
  },
};

const mutations = {
  SET_REQUESTS: (state, payload) => {
    state.requests = payload;
  },
  SET_DOCTOR_REQUESTS: (state, payload) => {
    state.doctor_requests = payload;
  },
  SET_ATTACHMENTS: (state, payload) => {
    state.attachments = payload;
  },
  SET_DOCTOR: (state, payload) => {
    state.doctor = payload;
  },
  SET_DETAIL_ID: (state, payload) => {
    state.detail_id = payload;
  },
  SET_FILTERS: (state, payload) => {
    state.filters = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
