import axios from "./axios-auth";

const state = {
  types: [],
  scan_types: [],
  main_types: [],
};

const getters = {
  types(state) {
    return state.types;
  },
  scan_types(state) {
    return state.scan_types;
  },
  main_types(state) {
    return state.main_types;
  },
};

const actions = {
  fetchTypes({ rootGetters, commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootGetters.API}/scan-types`)
        .then((response) => {
          if (response.data.code == 200) {
            commit("SET_TYPES", response.data.results);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchMainTypes({ rootGetters, commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootGetters.API}/main-types`)
        .then((response) => {
          if (response.data.code == 200) {
            commit("SET_MAIN_TYPES", response.data.results);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchScanTypes({ rootGetters, commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${rootGetters.API}/types`)
        .then((response) => {
          if (response.data.code == 200) {
            commit("SET_SCAN_TYPES", response.data.results);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createScanTypes({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${rootGetters.API}/types`, payload)
        .then((response) => {
          if (response.data.code == 200) {
            dispatch("fetchScanTypes");
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createScanSubTypes({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${rootGetters.API}/subtypes`, payload)
        .then((response) => {
          if (response.data.code == 200) {
            dispatch("fetchScanTypes");
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  editScanType({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${rootGetters.API}/types/${payload.id}`, payload)
        .then((response) => {
          if (response.data.code == 200) {
            dispatch("fetchScanTypes");
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  editScanName({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${rootGetters.API}/names/${payload.id}`, payload)
        .then((response) => {
          if (response.data.code == 200) {
            dispatch("fetchScanTypes");
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removeScanType({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${rootGetters.API}/types/${payload}`)
        .then((response) => {
          if (response.data.code == 200) {
            dispatch("fetchScanTypes");
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removeScanName({ rootGetters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${rootGetters.API}/names/${payload}`)
        .then((response) => {
          if (response.data.code == 200) {
            dispatch("fetchScanTypes");
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_TYPES: (state, payload) => {
    state.types = payload;
  },
  SET_SCAN_TYPES: (state, payload) => {
    state.scan_types = payload;
  },
  SET_MAIN_TYPES: (state, payload) => {
    state.main_types = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
