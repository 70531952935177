import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

// Modules
import auth from "./auth";
import teeth from "./teeth";
import areas from "./areas";
import users from "./users";
import types from "./types";
import requests from "./requests";
import scan_types from "./scan-types";
import loyality from "./loyality";
import chat from "./chat";
import permissions from "./permissions";

export default new Vuex.Store({
  state: {
    URL: "/", // production
    sidebar: "",
    properties: null,
    menuCollapsed: true,
  },
  getters: {
    URL(state) {
      return state.URL;
    },
    API(state) {
      return state.URL + "api";
    },
    ASSET(state) {
      return state.URL + "public";
    },
    primaryColor() {
      return "#059669";
    },
    secondaryColor() {
      return "#3730a3";
    },
    activeSidebar(state) {
      return state.sidebar;
    },
    sidebarProps(state) {
      return state.properties;
    },
    menuCollapsed(state) {
      return state.menuCollapsed;
    },
  },
  actions: {
    setSidebarComponent({ commit }, payload) {
      commit("SET_SIDEBAR_COMPONENT", payload);
    },
    setSidebarProperties({ commit }, payload) {
      commit("SET_SIDEBAR_PROPS", payload);
    },
    collapseMenu({ commit }) {
      commit("TOGGLE_MENU");
    },
  },
  mutations: {
    SET_SIDEBAR_COMPONENT: (state, payload) => {
      state.sidebar = payload;
    },
    SET_SIDEBAR_PROPS: (state, payload) => {
      state.properties = payload;
    },
    TOGGLE_MENU: (state) => {
      state.menuCollapsed = !state.menuCollapsed;
    },
  },
  modules: {
    auth,
    teeth,
    areas,
    users,
    types,
    requests,
    scan_types,
    loyality,
    chat,
    permissions,
  },
  plugins: [vuexLocal.plugin],
});
